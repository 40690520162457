import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Loadable from '@loadable/component';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import NonStretchedImage from '../../components/layout/NonStretchedImage';
import RelativeWrapper from '../../components/layout/RelativeWrapper';
import ContentWrapper from '../../components/layout/ContentWrapper';

import QuotationMark from '../../components/frills/QuotationMark';
import SingleTestimonial from '../../components/section/SingleTestimonial';

import { ViewportBreakpoints } from '../../components/cssConstants';

import FeatureListBreaker, { Features } from '../../components/cta/FeatureListBreaker';
import GetStarted from '../../components/cta/GetStarted';

import HeroH1 from '../../components/hero/HeroH1';
import HeroH2 from '../../components/hero/HeroH2';

const IntegrationPartnerCardDeck = Loadable(() => import('../../components/layout/IntegrationPartnerCardDeck'));

import H2 from '@rotaready/frecl/build/H2';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';

const DarkWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand180};
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 1440px 519px;
  background-image: url(${props => props.backgroundImage});
`;

const Hero = styled.div`
  padding: 80px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 120px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 45%;
    margin-right: 80px;
  }
`;

const HeroIllustration = styled.div`
  flex-grow: 1;

  .gatsby-image-wrapper {
    width: 100%;
    margin: 0 auto;
  }
`;

const CtaButtons = styled.div`
  margin: 30px auto;
  max-width: 180px;

  button {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-top: 40px;
    max-width: unset;

    button {
      width: unset;
      margin: 0 20px 0 0;
    }
  }
`;

const IntroSection = styled.div`
  text-align: center;
  padding: 20px 0 60px 0;

  > p {
    margin-bottom: 20px;
  }

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 20px 0 100px 0;
    width: 60%;
    margin: 0 auto;
  }
`;

const PartnerSection = styled.div`
  margin: 0 auto;
  text-align: center;
`;

const SectionTitle = styled(H2)`
  margin-bottom: 60px;
`;

const header = {
  backgroundColor: 'brand180',
  invertColors: true,
};

const categories = [
  { value: 'all', label: 'All categories' },
  { value: 'epos', label: 'EPOS' },
  { value: 'reservations', label: 'Reservations' },
  { value: 'payroll', label: 'Payroll' },
  { value: 'ats', label: 'Applicant Tracking (ATS)' },
  { value: 'pms', label: 'Property Management (PMS)' },
  { value: 'training', label: 'Training' },
  { value: 'comms', label: 'Communications' },
  { value: 'other', label: 'Other' },
];

function findCategoryById(id) {
  return categories.find(i => i.value === id);
}

const partners = [
  {
    id: 'payrollBrightPay', title: 'BrightPay', category: findCategoryById('payroll'), hidden: true,
  },
  {
    id: 'payrollSage', title: 'Sage Payroll', category: findCategoryById('payroll'), hidden: true,
  },
  {
    id: 'payrollXero', title: 'Xero', category: findCategoryById('payroll'), hidden: true, link: 'xero',
  },
  {
    id: 'eposEposnow', title: 'Epos Now', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'eposLightspeed', title: 'Lightspeed', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'trainingFlow', title: 'Flow Hospitality', category: findCategoryById('training'), hidden: true,
    externalLink: 'https://www.flowhospitalitytraining.co.uk/en/',
  },
  {
    id: 'trainingCpl', title: 'CPL Learning', category: findCategoryById('training'), hidden: true,
  },
  {
    id: 'reservationsSevenRooms', title: 'SevenRooms', category: findCategoryById('reservations'), hidden: true,
  },
  {
    id: 'eposIcrTouch', title: 'ICRTouch', category: findCategoryById('epos'), hidden: true, link: 'icrtouch',
  },
  {
    id: 'eposComtrex', title: 'Comtrex', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'eposPointOne', title: 'pointOne', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'eposTevalis', title: 'Tevalis', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'commsYapster', title: 'Yapster', category: findCategoryById('comms'), hidden: true,
  },
  {
    id: 'reservationsAccessCollins', title: 'Access Collins', category: findCategoryById('reservations'), hidden: true,
  },
  {
    id: 'eposAccessEpos', title: 'Access EPOS (StockLink)', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'eposLightspeedIKentoo', title: 'Lightspeed K Series (iKentoo)', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'analyticsTenzo', title: 'Tenzo', category: findCategoryById('other'), hidden: true, link: 'tenzo',
  },
  {
    id: 'eposGoodtill', title: 'Goodtill by SumUp', category: findCategoryById('epos'), hidden: true, link: 'goodtill-sumup',
  },
  {
    id: 'reservationsOpenTable', title: 'OpenTable', category: findCategoryById('reservations'), hidden: true,
  },
  {
    id: 'eposCentegra', title: 'Centegra', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'eposNcrAloha', title: 'NCR Aloha', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'reservationsResDiary', title: 'ResDiary', category: findCategoryById('reservations'), hidden: true, link: 'resdiary',
  },
  {
    id: 'atsEploy', title: 'Eploy', category: findCategoryById('ats'), hidden: true, link: 'eploy',
  },
  {
    id: 'otherWagestream', title: 'Wagestream', category: findCategoryById('other'), hidden: true,
  },
  {
    id: 'payrollWsAndCo', title: 'Williams Stanley & Co', category: findCategoryById('payroll'), hidden: true,
  },
  {
    id: 'payrollPayCaptain', title: 'PayCaptain', category: findCategoryById('payroll'), hidden: true, link: 'paycaptain',
  },
  {
    id: 'eposZonal', title: 'Zonal', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'otherTipjar', title: 'Tipjar', category: findCategoryById('other'), hidden: true,
  },
  {
    id: 'commsAzuronaut', title: 'Azuronaut', category: findCategoryById('comms'), hidden: true,
  },
  {
    id: 'commsStaffbase', title: 'Staffbase', category: findCategoryById('comms'), hidden: true,
  },
  {
    id: 'atsRecruitGenie', title: 'Recruit Genie', category: findCategoryById('ats'), hidden: true,
  },
  {
    id: 'atsTeamtailor', title: 'Teamtailor', category: findCategoryById('ats'), hidden: true,
  },
  {
    id: 'pmsGuestline', title: 'Guestline Rezlynx', category: findCategoryById('pms'), hidden: true,
  },
  {
    id: 'atsTalentFunnel', title: 'Talent Funnel', category: findCategoryById('ats'), hidden: true, link: 'talent-funnel',
  },
  {
    id: 'trainingTayl', title: 'Tayl', category: findCategoryById('training'), hidden: true,
  },
  {
    id: 'eposClover', title: 'Clover', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'commsWorkvivo', title: 'Workvivo', category: findCategoryById('comms'), hidden: true,
  },
  {
    id: 'eposNewbridge', title: 'Newbridge', category: findCategoryById('epos'), hidden: true,
  },
  {
    id: 'otherActeol', title: 'Acteol', category: findCategoryById('other'), hidden: true,
  },
  {
    id: 'otherEarlyPay', title: 'Access EarlyPay', category: findCategoryById('other'), hidden: true,
  },
  {
    id: 'otherHospitalityRewards', title: 'Hospitality Rewards', category: findCategoryById('other'), hidden: true,
  },
];

class IntegrationsPage extends React.Component {
  renderCardFallback = (partner) => {
    if (partner.link) {
      return (<li><Link to={`/integrations/${partner.link}`}>{partner.title}</Link></li>);
    } else if (partner.externalLink) {
      return (<li><a href={partner.externalLink} target="_blank" rel="noopener noreferrer">{partner.title}</a></li>);
    }

    return (<li>{partner.title}</li>);
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Integrations - Rotaready"
          description="Rotaready integrates with a whole host of systems, including EPOS, payroll, reservations, training and more. Or use our open API to build your own integration."
          url="integrations"
        />
        <DarkWrapper backgroundImage={data.heroBackgroundMask.childImageSharp.fixed.src}>
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <HeroH1 color="brand" text="Integrations" />
                  <HeroH2 color="white" text="Real-time integrations, all included for free" />

                  <H4 uistyle="white">
                    We know it's crucial for your rota software to talk to the other
                    systems within your business. From payroll to EPOS, reservations
                    to training, we help you get set-up with whichever solutions you choose.
                    <br />
                    <br />
                    Integrate with an existing partner, choose from those we already
                    work with or get started with someone completely new.
                  </H4>

                  <CtaButtons>
                    <Link to="/demo">
                      <Button uistyle="primary" text="Request demo" size="lg" />
                    </Link>
                    <Link to="/contact">
                      <Button uistyle="white" text="Speak to the team" size="lg" ghost borderless />
                    </Link>
                  </CtaButtons>
                </HeroBody>

                <HeroIllustration>
                  <NonStretchedImage fluid={data.heroImage.childImageSharp.fluid} />
                </HeroIllustration>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </DarkWrapper>

        <FeatureListBreaker
          rows={1}
          excludeFeature={Features.INTEGRATIONS}
          headingText="Other features"
        />

        <RelativeWrapper backgroundColor="white">
          <ResponsiveContainer>
            <IntroSection>
              <SectionTitle uistyle="brand160" text="Rotaready integrates with a whole host of key tools" />
              <Text size="xl">
                From EPOS and reservations to training and payroll, we let
                you handpick the best solution for each area of your business
                without the hassle and cost of linking them together.
              </Text>
              <Text size="xl">
                We're continually adding new real-time integrations to our ecosystem,
                so the list below may not include every partner we integrate with.
                Get in touch to discover more or request a new integration.
              </Text>
            </IntroSection>
          </ResponsiveContainer>
        </RelativeWrapper>

        <RelativeWrapper backgroundColor="brand10">
          <ResponsiveContainer>
            <ContentWrapper>
              <PartnerSection>
                <SectionTitle uistyle="brand160">Our real-time partners</SectionTitle>

                <IntegrationPartnerCardDeck
                  images={data}
                  categories={categories}
                  partners={partners}
                  fallback={partners.map(partner => (
                    <ul>
                      {this.renderCardFallback(partner)}
                    </ul>
                  ))}
                />
              </PartnerSection>
            </ContentWrapper>
          </ResponsiveContainer>
        </RelativeWrapper>

        <SingleTestimonial
          image={data.testimonialImage.childImageSharp.fluid}
          imageAlt="People at Topgolf"
        >
          <QuotationMark width={60} />

          <Text size="xl" uistyle="brand160">
            We wanted Rotaready to integrate with our 3rd party HR software, to ensure staff and absence records
            remain consistent between the two systems. We wanted the data to update live, in real time.
          </Text>

          <Text size="xl" uistyle="brand160">
            Working with multiple providers isn't traditionally easy but Rotaready have been proactive and responsive
            and have helped us plan and manage the process every step of the way.
          </Text>

          <Text size="xl" uistyle="brand160">
            They're always positive and engaging when we've wanted to talk, even if we simply wanted some reassurance!
          </Text>

          <Text size="lg">
            Jean Johnson, Topgolf
          </Text>
        </SingleTestimonial>

        <RelativeWrapper backgroundColor="white">
          <GetStarted />
        </RelativeWrapper>
      </Body>
    );
  }
}

export default IntegrationsPage;

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "misc/hero-integrations.png" }) {
      childImageSharp {
        fluid(maxWidth: 562, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }

    heroBackgroundMask: file(relativePath: { eq: "misc/hero-mask-brand180.png" }) {
      childImageSharp {
        fixed(height: 519, width: 1440, quality: 100) {
          src
        }
      }
    }

    analyticsTenzo: file(relativePath: { eq: "integrations/analytics-tenzo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, width: 165, quality: 100)
      }
    }

    atsEploy: file(relativePath: { eq: "integrations/ats-eploy.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 44, width: 151, quality: 100)
      }
    }

    commsYapster: file(relativePath: { eq: "integrations/comms-yapster.png" }) {
      childImageSharp {
        gatsbyImageData(height: 90, width: 90, quality: 100)
      }
    }

    eposEposnow: file(relativePath: { eq: "integrations/epos-eposnow.png" }) {
      childImageSharp {
        gatsbyImageData(height: 37, width: 140, quality: 100)
      }
    }

    eposGoodtill: file(relativePath: { eq: "integrations/epos-goodtill.png" }) {
      childImageSharp {
        gatsbyImageData(height: 50, width: 163, quality: 100)
      }
    }

    eposIbsStockLink: file(relativePath: { eq: "integrations/epos-ibs-stocklink.png" }) {
      childImageSharp {
        gatsbyImageData(height: 58, width: 138, quality: 100)
      }
    }

    eposAccessEpos: file(relativePath: { eq: "integrations/epos-access.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 42, width: 150, quality: 100)
      }
    }

    eposIcrTouch: file(relativePath: { eq: "integrations/epos-icrtouch.png" }) {
      childImageSharp {
        gatsbyImageData(height: 35, width: 199, quality: 100)
      }
    }

    eposLightspeed: file(relativePath: { eq: "integrations/epos-lightspeed.png" }) {
      childImageSharp {
        gatsbyImageData(height: 45, width: 200, quality: 100)
      }
    }

    eposLightspeedIKentoo: file(relativePath: { eq: "integrations/epos-ikentoo.png" }) {
      childImageSharp {
        gatsbyImageData(height: 69, width: 140, quality: 100)
      }
    }

    eposCentegra: file(relativePath: { eq: "integrations/epos-centegra.png" }) {
      childImageSharp {
        gatsbyImageData(height: 78, width: 114, quality: 100)
      }
    }

    eposComtrex: file(relativePath: { eq: "integrations/epos-comtrex.png" }) {
      childImageSharp {
        gatsbyImageData(height: 47, width: 160, quality: 100)
      }
    }

    eposNcrAloha: file(relativePath: { eq: "integrations/epos-ncr-aloha.png" }) {
      childImageSharp {
        gatsbyImageData(height: 81, width: 120, quality: 100)
      }
    }

    eposPointOne: file(relativePath: { eq: "integrations/epos-pointone.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 95, width: 95, quality: 100)
      }
    }

    eposTevalis: file(relativePath: { eq: "integrations/epos-tevalis.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 79, width: 79, quality: 100)
      }
    }

    eposZonal: file(relativePath: { eq: "integrations/epos-zonal.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 40, width: 138, quality: 100)
      }
    }

    otherWagestream: file(relativePath: { eq: "integrations/other-wagestream.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 24, width: 191, quality: 100)
      }
    }

    payrollBrightPay: file(relativePath: { eq: "integrations/payroll-brightpay.png" }) {
      childImageSharp {
        gatsbyImageData(height: 39, width: 176, quality: 100)
      }
    }

    payrollSage: file(relativePath: { eq: "integrations/payroll-sage.png" }) {
      childImageSharp {
        gatsbyImageData(height: 38, width: 99, quality: 100)
      }
    }

    payrollXero: file(relativePath: { eq: "integrations/payroll-xero.png" }) {
      childImageSharp {
        gatsbyImageData(height: 70, width: 70, quality: 100)
      }
    }

    payrollWsAndCo: file(relativePath: { eq: "integrations/payroll-wsandco.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 64, width: 105, quality: 100)
      }
    }

    payrollPayCaptain: file(relativePath: { eq: "integrations/payroll-paycaptain.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 36, width: 184, quality: 100)
      }
    }

    reservationsAccessCollins: file(relativePath: { eq: "integrations/reservations-access-collins.png" }) {
      childImageSharp {
        gatsbyImageData(height: 46, width: 160, quality: 100)
      }
    }

    reservationsOpenTable: file(relativePath: { eq: "integrations/reservations-opentable.png" }) {
      childImageSharp {
        gatsbyImageData(height: 66, width: 104, quality: 100)
      }
    }

    reservationsResDiary: file(relativePath: { eq: "integrations/reservations-resdiary.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 110, width: 110, quality: 100)
      }
    }

    reservationsSevenRooms: file(relativePath: { eq: "integrations/reservations-sevenrooms.png" }) {
      childImageSharp {
        gatsbyImageData(height: 69, width: 137, quality: 100)
      }
    }

    trainingCpl: file(relativePath: { eq: "integrations/training-cpl.png" }) {
      childImageSharp {
        gatsbyImageData(height: 54, width: 177, quality: 100)
      }
    }

    trainingFlow: file(relativePath: { eq: "integrations/training-flow.png" }) {
      childImageSharp {
        gatsbyImageData(height: 47, width: 100, quality: 100)
      }
    }

    otherTipjar: file(relativePath: { eq: "integrations/other-tipjar.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 57, width: 136, quality: 100)
      }
    }

    commsAzuronaut: file(relativePath: { eq: "integrations/comms-azuronaut.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 79, width: 140, quality: 100)
      }
    }

    commsStaffbase: file(relativePath: { eq: "integrations/comms-staffbase.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 41, width: 190, quality: 100)
      }
    }

    atsRecruitGenie: file(relativePath: { eq: "integrations/ats-recruitgenie.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 27, width: 200, quality: 100)
      }
    }

    atsTeamtailor: file(relativePath: { eq: "integrations/ats-teamtailor.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 57, width: 182, quality: 100)
      }
    }

    atsHarri: file(relativePath: { eq: "integrations/ats-harri.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 56, width: 139, quality: 100)
      }
    }

    pmsGuestline: file(relativePath: { eq: "integrations/pms-guestline.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 46, width: 203, quality: 100)
      }
    }

    atsTalentFunnel: file(relativePath: { eq: "integrations/ats-talent-funnel.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 31, width: 179, quality: 100)
      }
    }

    trainingTayl: file(relativePath: { eq: "integrations/training-tayl.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 53, width: 105, quality: 100)
      }
    }

    eposClover: file(relativePath: { eq: "integrations/epos-clover.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 45, width: 180, quality: 100)
      }
    }

    commsWorkvivo: file(relativePath: { eq: "integrations/comms-workvivo.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 54, width: 195, quality: 100)
      }
    }

    eposNewbridge: file(relativePath: { eq: "integrations/epos-newbridge.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 43, width: 161, quality: 100)
      }
    }

    otherActeol: file(relativePath: { eq: "integrations/other-acteol.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 106, width: 88, quality: 100)
      }
    }

    otherEarlyPay: file(relativePath: { eq: "integrations/other-earlypay.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 52, width: 231, quality: 100)
      }
    }

    otherHospitalityRewards: file(relativePath: { eq: "integrations/other-hospitality-rewards.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 86, width: 98, quality: 100)
      }
    }

    testimonialImage: file(relativePath: { eq: "testimonial/feature-topgolf.png" }) {
      childImageSharp {
        fluid(maxWidth: 561, quality: 25) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`;
